.footer{
    gap: 86px;
    z-index: 2;
    width: 100vw;
    display: grid;
    overflow: hidden;
    position: relative;
    padding: 70px 86px;
    align-items: flex-start;
    justify-content: space-evenly;
    grid-template-columns: 1fr 1fr;
    background: var(--green-background);
}
@media only screen and (max-width: 760px) {
    .footer{
        gap: 0;
        padding: 50px;
        grid-template-columns: 1fr;
    }
}

.menu{
    text-align: start;
    padding-left: 24px;
    align-items: flex-start;
    border-left: 1px solid rgba(255,255,255,0.15);
}

@media (max-width: 886px) {
    .menu{
        padding-top: 0;
        margin-top: 50px;
    }
}
.navigation{
    color: #FFF;
}
.navigation > span{
    cursor: pointer;
    transition: 250ms ease-in-out;
    border-bottom: 1px solid rgba(255,255,255,0);
}
.navigation > span:hover{
    border-bottom: 1px solid rgba(255,255,255,0.5);
}