
.container{
    margin-left: auto;
    max-width: 400px;
    position: relative;
}

@media (max-width: 886px) {
    .container{
        max-width: none;
    }
}

@media (max-width: 760px) {
    .content_bg{
        width: 100%;
        border-radius: 0;
    }
}
.content{
    gap: 32px;
    display: grid;
    margin: 0 auto;
    grid-column: 2;
    align-items: center;
    grid-template-columns: 1fr;  
}

@media (max-width: 760px) {
    .content{
        gap: 20px;
        padding: 0;
    }
}

.message{
    display: flex;
    border-radius: 12px;
    flex-direction: column;
}


.rez{
    gap: 12px;
    display: flex;
    color: #000;
    flex-direction: column;
}

input{
    width: 100%;
    color: #000;
    padding: 16px;
    border-radius: 6px;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.15);
}
.send_button{
    width: 100%;
    height: 55px;
    font-size: 16px;
}

.close{
    top: 0;
    left: 0;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    position: absolute;
}

.video{
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    pointer-events: none;
    background: #000000AA;
    transition: 500ms ease-in-out;
}

.video[data-active="true"]{
    opacity: 1;
    pointer-events: all;
}

.video > video{
    z-index: 9;
    margin: 0 auto;
    max-height: 50vh;
    border-radius: 32px;
    width: min(90vw,600px);
}

.error{
    text-align: start;
    color: rgb(220, 78, 78);
}