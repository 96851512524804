:root{
  --max-width:1090px;
  --white-background:#FFF;
  --green-background:#202b2c;
}

body {
  margin: 0;
  color: #22231f;
  overscroll-behavior: none;
  background: var(--green-background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito Sans", sans-serif;
}

html{
  width: 100vw;
  box-sizing: border-box;
  overscroll-behavior: none;
  font-optical-sizing: auto;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scroll-behavior: smooth;
}

*{
  box-sizing: border-box;
}

li{
  list-style: none;
  cursor: pointer;
}

section{
  position: relative;
  min-height: 100vh;
  padding: 110px 128px;
}
@media (max-width: 886px) {
  section{
    padding: 110px 32px;
  }
}

h1,h2,h3,h4{
  margin: 4px 0px;
}

h1{
  font-size: 53px;
  font-weight: 400;
}

label{
  font-size: 14px;
  font-weight: 400;
  color: rgb(34, 35, 31);
}

p{
  font-size: 18px;
  margin: 4px 0px;
  color: #000;
  opacity: 0.8;
}

button{
  gap:8px;
  display: flex;
  color: #FFF;
  padding: 12px;
  cursor: pointer;
  font-weight: 700;
  position: relative;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background: rgb(153,111,49);
  min-width: min(150px,90vw);
  border: 1px solid rgba(255, 255, 255, 0.15);
  transition: 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

button:hover {
  box-shadow: 0 0 30px rgba(255,255,255,0.5);
}

button:disabled{
  opacity: 0.5;
  pointer-events: none;
  filter: grayscale(0.5);
}

strong{
  color: #000;
  font-size: 16px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  background: transparent;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px 5px transparent;
  border: solid 1px transparent;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px 5px rgba(255, 255, 255, 0.15);
  border: solid 4px transparent;
  border-radius: 14px;
}

