
.container {
  top: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  position: fixed;
  align-items: center;
}
  .content_bg{
    inset: 0;
    z-index: 1;
    height: 100vh;
    position: absolute;
    background: linear-gradient(0,var(--green-background),transparent)
  }

.video_container {
  inset: 0;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
  position: absolute;
  transition: 100ms linear;
}
  
  .video {
    top: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
  }

  .text{
    flex:1;
    z-index: 1;
    height: 70vh;
    display: flex;
    text-align: start;
    justify-content: end;
    flex-direction: column;
  }

  
    
  .welcome_button{
    height: 65px;
    padding: 16px;
    font-size: 16px;
    margin-top: 1rem;
    border-radius: 16px;
    background: #202b2c;
    box-shadow: 0 0 50px #202b2c;
  }
