.container{
    display: grid;
    min-height: auto;
    grid-template-columns: 35vw 1fr;
    background: var(--green-background);
}
@media (max-width: 886px) {
    .container{
         grid-template-columns: 1fr;
    }
}
.canvas{
    transform: translateX(0)
}

@media (max-width: 886px) {
    .canvas{
        width: 100vw;
        transform: translateX(-40px);
    }
}

.text{
    gap:0;
    padding: 32px;
    padding-top:64px;
}

@media (max-width: 886px) {
    .text{
        width: 90vw;
        padding-top:82px;
        padding-inline: 32px;
    }
}