.container{
  min-height: auto;
  background: var(--white-background)
}
.content{
  gap: 48px;
  display: flex;
  margin: 0 auto;
  padding: 0 32px;
  flex-direction: column;
  max-width: var(--max-width);
}

@media (max-width:880px) {
  .content {
    padding: 0;
  }
}
.header_title{
  line-height: 36spx;
  text-align: start;
  font-size: min(6cqmin,42px);
}
.header_desc{
  font-size: 18px;
  text-align: start;
}

.grid {
  gap:82px;
  display: grid;
  grid-template-columns: 350px 1fr;
}

  @media only screen and (max-width: 760px) {
    .grid {
      gap: 0;
      grid-template-columns: 1fr 0;
    }
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item{
  gap:16px;
  display: grid;
  height:135px;
  grid-template-columns: 50px 1fr;
}
.items_icon,
.items_icon_last{
  color: #FFF;
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  align-items: center;
  justify-content: center;
  background: rgb(153,111,49);
}

.items_icon::after{
  content: '';
  top: 0;
  width: 0.1px;
  height: 150px;
  position: absolute;
  border: 1px solid rgba(153,111,49,0.25)
}
.video_container{
    overflow: hidden;
    border-radius: 32px;
    position: relative;
}
.video{
  object-fit: cover;
  width: 100%;
  height: 100%;
}