.App {
  display: flex;
  text-align: center;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
main{
  z-index: 2;
  display:flex;
  padding-top: 100vh;
  flex-direction: column;
  background: var(---white-background);
}
