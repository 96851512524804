.container{
    inset: 0; 
    z-index: 9;
    display: flex;
    position: fixed;
    align-items: center;
    pointer-events: none;
    background: #143c41;
    justify-content: center;
    transition: 500ms;
}

.logo{
    width: 125px;
    object-fit: contain;
    animation: pulse 3000ms linear infinite;
}

@keyframes pulse {
    0%{
        transform: scale(0.8);
    }
    50%{
        transform: scale(1);
    }
    100%{
        transform: scale(0.8);
    }
}


@keyframes loading {
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}

