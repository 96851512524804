  .container{
        display: flex;
        padding-top: 0;
        font-size: 22px;
        position: relative;
        justify-content: center;
        background: var(--green-background);
    }

    .content{
        gap: 64px;
        z-index: 2;
        display: grid;
        align-items: center;
    }

    .card
    {
        height: 300px;
        width: 275px;
        color: #FFF;
        display: flex;
        overflow: hidden;
        text-align: start;
        border-radius: 14px;
        border-radius: 12px;
        box-sizing: border-box;
        flex-direction: column;
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        background: rgba(136,136,136,.05);
        border: 1px solid rgba(255, 255, 255, .1);
    }

    @media (max-width:880px) {
      .card {
          width: 75vw;
          height: auto;
      }
  }
  

    .dots{
      bottom: 0;
      z-index: 1;
      width: 100vw;
      height: 100%;
      opacity: 0.05;
      position: absolute;
      background-position: 0 0;
      background-size: 15px 15px;
      background-image: radial-gradient(#ededed 2px, transparent 0);
      mask-image: linear-gradient(180deg, transparent, #000 200px, #000 calc(100% - 200px), transparent);
      -webkit-mask-image: linear-gradient(180deg, transparent, #000 200px, #000 calc(100% - 200px), transparent);
    }

 
    .button{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .button>span{
        font-size: 16px;
        text-decoration: underline;
    }
      .content_bg{
    inset: 0;
    height: 100%;
    position: absolute;
    background: linear-gradient(180deg, #2C3B3B 5%,transparent);
  }
 
    .items{
        flex:1;
        gap: 48px;
        display: grid;
        align-items: flex-start;
        grid-template-columns: auto auto auto;
    }
    
    @media (max-width:760px) {
        .items{
            gap: 24px;
            grid-template-columns: auto;
        }
      }
      
    .content::-webkit-scrollbar {
        display: none;
      }

      .p{
        font-size: 16px;
        font-weight: 300;
        color: #FFFFFFAA;
        letter-spacing: 0.69px;
      }

      .cirle{
          --pos-x:15%;
          --pos-y:35%;
          z-index: -3;
            opacity: .2;
            width: 25vmax;
            height: 25vmax;
            position: absolute;
            top: var(--pos-y);
            left: var(--pos-x);
            background: radial-gradient(closest-side, #549095, transparent);
      }