.header_desktop {
    top: 0;
    z-index: 9;
    color:#000;
    display: flex;
    font-size: 14px;
    position: sticky;
    padding: 15px 64px;
    align-items: center;
    justify-content: space-between;
    transition: 250ms ease-in-out;
  }

  .header_desktop_active{
    backdrop-filter: blur(13px);
    background: rgba(222, 221, 217,0.25);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .nav_mobile{
    inset: 0;
    gap: 32px;
    width: 100vw;
    height: 100vh;
    display: flex;
    font-size: 18px;
    position: fixed;
    padding: 18px 24px;
    flex-direction: column;
    background: rgb(222 221 217);
}
.nav_mobile > div{
    padding: 12px;
}
  
.header_mobile {
    top: 0;
    z-index: 3;
    color:#000;
    display: none;
    font-size: 14px;
    position: sticky;
    padding: 24px 32px;
    align-items: center;
    backdrop-filter: blur(13px);
    justify-content: space-between;
    background: rgba(222, 221, 217,0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

.nav{
    flex: 1;
    gap: 18px;
    display: flex;
    justify-content: flex-end;
    
}
.nav_item{
    font-size: 17px;
    font-weight: 600;
    position: relative;
}
.nav_item::after{
    content: '';
    bottom: 0;
    height: 1px;
    display: flex;
    background: #202b2c;
    transition: 500ms ease-in-out;
}
.nav_item[data-active="true"]::after{
    width: 100%;
}
.nav_item[data-active="false"]::after{
    width:0;
}

.nav_item:hover::after {
    content: '';
    bottom: 0;
    height: 1px;
    width: 100%;
    display: flex;
    background: #202b2c;
    animation: nav_item_anim 700ms ease-in-out;
}
@keyframes nav_item_anim {
    from{width: 0%;}
    to{width: 100%;}
}

.nav > div {
    cursor: pointer;
    font-size: 15px;
    padding: 12px; 
}

.nav > button {
    gap:8px;
    display: flex;
    font-size: 12px;
    cursor: pointer;
    color: #161F3A;
    position: relative;
    padding: 12px 32px;
    border-radius: 13px;
    letter-spacing: 2px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(255, 255, 255, 0.15);
}

.nav > button::after{
    content: "";
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: translate(5px,5px);
    position: absolute;
    border-radius: 13px;
    background: #202b2c33;
    border: 1px solid rgba(255, 255, 255, 0.15);
    transition: 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.nav > button:hover::after {
  transform: translate(0, 0);
}


@media only screen and (max-width: 960px) {
    .header_desktop {
        display: none;
    }
    .header_mobile{
        display: flex;
    }
}